<template>
  <div :class="{ size, disabled }">
    <label v-if="!!label" :for="id">{{ label }}</label>
    <textarea
      v-if="multiline"
      :id="id"
      :value="value"
      :placeholder="placeholder"
      :rows="rows"
      :disabled="disabled"
      :spellcheck="spellcheck"
      :autocomplete="autocomplete"
      @change="valueChanged"
      @input="valueChanged"
      @blur="$emit('blur', $event)"
      @keyup="$emit('keyup', $event)"
    ></textarea>
    <input
      v-else
      :id="id"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :spellcheck="spellcheck"
      :autocomplete="autocomplete"
      @change="valueChanged"
      @input="valueChanged"
      @blur="$emit('blur', $event)"
      @keyup="$emit('keyup', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: () => Math.random().toString(36).substr(2, 5)
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiline: {
      type: Boolean,
      default: () => false
    },
    size: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 5
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    spellcheck: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    }
  },
  methods: {
    valueChanged(e) {
      this.$emit('change', e.target.value);
      this.$emit('input', e.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 5px;

  > * {
    min-width: 0;
    min-height: 0;
  }

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  input,
  textarea {
    padding: 8px 12px;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 2px;
    border: 1px solid var(--theme-on-background-dark);
    color: var(--theme-on-background);
    background: var(--theme-background);
    outline: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:not([disabled]) {
      &:hover {
        border-color: var(--theme-on-background);
        color: var(--theme-on-background);
      }

      &:focus,
      &:active {
        border-color: var(--theme-on-background);
        color: var(--theme-on-background);
        outline: none;
      }
    }
  }

  textarea {
    resize: none;
    min-height: 62px;
  }

  &.disabled {
    opacity: 0.5;
  }
}
</style>
