import { am } from './utils';

import httpClient from '@/utils/httpClient';
import logger from '@/utils/logger';

export const SEARCH = am('SEARCH');

export const state = {
  isSearchRequestPending: false,
  isSearchRequestFailed: false,
  collection: [],
  total: 0,
  size: 10,
  from: 0,
  sort: '',
  aggs: null
};

const getters = {};

export const actions = {
  async search({ commit }) {
    commit(SEARCH.STARTED);
    try {
      const params = new URLSearchParams(document.location.search);
      const { data, total, aggs } = await httpClient.get(`/api/search` + document.location.search);

      commit(SEARCH.COMPLETED, {
        data,
        total,
        aggs,
        size: params.get('size') ? +params.get('size') : 10,
        from: params.get('from') ? +params.get('from') : 0,
        sort: params.get('sort') || ''
      });
    } catch (e) {
      logger.error(e);
      commit(SEARCH.FAILED);
    }
  },
  async export() {
    try {
      return await httpClient.get(`/api/search/export/csv` + document.location.search);
    } catch (e) {
      logger.error(e);
    }
  }
};

export const mutations = {
  [SEARCH.STARTED](state) {
    state.isSearchRequestPending = true;
    state.isSearchRequestFailed = false;
    state.collection = [];
    state.aggs = null;
  },
  [SEARCH.FAILED](state) {
    state.isSearchRequestPending = false;
    state.isSearchRequestFailed = true;
  },
  [SEARCH.COMPLETED](state, { data, total, size, from, sort, aggs }) {
    state.isSearchRequestPending = false;
    state.collection = data;
    state.total = total;
    state.size = size;
    state.from = from;
    state.sort = sort;
    state.aggs = aggs;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
