<template>
  <form class="search-wrapper" :class="{ compact }" @submit.prevent="submit">
    <div>
      <ps-text-field v-model="value" placeholder="Search for a patent..." size="large" />
    </div>
    <div class="button-wrapper">
      <ps-button color="primary" :disabled="!value || !value.length">Search</ps-button>
      <ps-button v-if="!compact" @click.prevent.stop="explore">Explore</ps-button>
    </div>
  </form>
</template>

<script>
import Button from './common/Button';
import TextField from './common/TextField';

export default {
  components: {
    'ps-button': Button,
    'ps-text-field': TextField
  },
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: ''
    };
  },
  watch: {
    $route: {
      handler({ query }) {
        this.value = query.qs;
      },
      immediate: true
    }
  },
  methods: {
    explore() {
      this.$trackEvent('Explore clicked');
      this.$router.push({ path: '/search' });
    },
    submit() {
      if (!this.value || !this.value.length) {
        return;
      }

      this.$trackEvent('Search clicked');
      this.$router.push({ path: '/search', query: { qs: this.value } });
    }
  }
};
</script>
<style lang="scss">
form.search-wrapper {
  display: grid;
  grid-gap: 10px;
  padding-top: 5px;

  &.compact {
    grid-template-columns: minmax(0, 1fr) max-content;
  }

  &:not(.compact) {
    .button-wrapper {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: max-content max-content;
      justify-content: center;
    }
  }
}
</style>
