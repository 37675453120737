import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

import Nav from './components/Nav.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      components: {
        default: Home,
        nav: Nav
      },
      props: {
        nav: { search: false, logo: true, help: false }
      }
    },
    {
      path: '/search',
      name: 'search',
      components: {
        default: () => import(/* webpackChunkName: "search" */ './views/Search.vue'),
        nav: Nav
      }
    },
    {
      path: '/patents/:id',
      name: 'patent',
      components: {
        default: () => import(/* webpackChunkName: "patent" */ './views/Patent.vue'),
        nav: Nav
      }
    },
    {
      path: '*',
      components: {
        default: () => import(/* webpackChunkName: "nf" */ './views/NotFound.vue'),
        nav: Nav
      }
    }
  ]
});
