<template>
  <section class="home">
    <div class="logo-wrapper">
      <img src="./../assets/logo.svg" alt="Patent Searchr logo" />
    </div>
    <ps-search-box />
  </section>
</template>

<script>
import SearchBox from './SearchBox';

export default {
  components: {
    'ps-search-box': SearchBox
  }
};
</script>

<style lang="scss" scoped>
.home {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-template-columns: 400px;
  grid-gap: 50px;
  align-items: center;
  justify-content: center;
  height: 100%;
  align-content: center;
  transform: translateY(-15%);

  .logo-wrapper {
    text-align: center;
  }
  img {
    max-width: 300px;
    max-height: 300px;
  }
}
</style>
