export default function (text) {
  if (!text || !text.length) {
    return '';
  }
  const div = document.createElement('div');
  div.innerHTML = text;
  if (Number.isNaN(+div.innerText)) {
    return div.innerHTML;
  }
  return div.innerHTML.replace(div.innerText, (+div.innerText).toLocaleString('US'));
}
