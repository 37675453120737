export const state = {
  helpVisible: false
};

export const actions = {
  async showHelp({ commit }) {
    commit('SHOW_HELP');
  },
  async hideHelp({ commit }) {
    commit('SHOW_HELP');
  },
  async toggleHelp({ commit, state }) {
    if (state.helpVisible) {
      commit('HIDE_HELP');
    } else {
      commit('SHOW_HELP');
    }
  }
};

export const mutations = {
  ['SHOW_HELP'](state) {
    state.helpVisible = true;
  },
  ['HIDE_HELP'](state) {
    state.helpVisible = false;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
