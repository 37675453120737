const EventEmitter = require('events');

export class HttpClient extends EventEmitter {
  constructor(getAuthToken) {
    super();
    if (!getAuthToken) {
      throw new Error('getAuthToken function should be defined');
    }
    this.getAuthToken = getAuthToken;

    for (const method of ['get', 'post', 'put', 'patch', 'delete']) {
      this[method] = (url, body) =>
        this.request(url, {
          method: method.toUpperCase(),
          body
        });
    }
  }

  async request(url, args = {}) {
    if (!url || !url.length) {
      throw new Error('Url is not defined');
    }

    const { json = true, ...options } = args;

    const authToken = this.getAuthToken();

    options.headers = {
      Authorization: authToken && `Bearer ${authToken}`,
      Cache: 'no-cache',
      ...(options.headers || {})
    };

    if (json) {
      options.headers['Content-Type'] = 'application/json';
      if (options.body) {
        options.body = JSON.stringify(options.body);
      }
    }

    const response = await fetch(url, options);

    if (response.status < 200 || response.status > 300) {
      const payload = {
        url,
        response: {
          status: response.status,
          statusText: response.statusText
        }
      };
      this.emit(response.status, payload);
      return Promise.reject(payload);
    }

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    }

    return response.text();
  }
}

export default new HttpClient(() => localStorage.getItem('PATENT_SEARCHR_AUTH_TOKEN'));
