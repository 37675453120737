import { am } from './utils';

import httpClient from '@/utils/httpClient';
import logger from '@/utils/logger';

export const GET_PATENT = am('GET_PATENT');

export const state = {
  isRequestPending: false,
  isRequestFailed: false,

  patent: null
};

const getters = {};

export const actions = {
  async getById({ commit }, { id }) {
    commit(GET_PATENT.STARTED);
    try {
      const patent = await httpClient.get(`/api/patents/${id}` + document.location.search);
      commit(GET_PATENT.COMPLETED, {
        patent
      });
    } catch (e) {
      if (e && e.status === 404) {
        commit(GET_PATENT.COMPLETED, {
          patent: null
        });
        return;
      }

      commit(GET_PATENT.FAILED);
      logger.error(e);
    }
  }
};

export const mutations = {
  [GET_PATENT.STARTED](state) {
    state.isRequestPending = true;
    state.isRequestFailed = false;
    state.patent = null;
  },
  [GET_PATENT.FAILED](state) {
    state.isRequestPending = false;
    state.isRequestFailed = true;
  },
  [GET_PATENT.COMPLETED](state, { patent }) {
    state.isRequestPending = false;
    state.patent = patent;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
