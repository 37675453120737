<template>
  <div class="home-view">
    <ps-home />
  </div>
</template>

<script>
import Home from './../components/Home.vue';

export default {
  components: {
    'ps-home': Home
  },
  mounted() {
    this.$trackEvent('Home page opened');
  }
};
</script>

<style lang="scss" scoped>
.home-view {
  width: 100%;
  height: 100%;
}
</style>
