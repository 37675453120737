import { am } from './utils';

import httpClient from '@/utils/httpClient';
import logger from '@/utils/logger';

export const INITIALIZE = am('INITIALIZE');

export const state = {
  isGetRequestPending: false,
  isGetRequestFailed: false,

  providers: null
};

const getters = {};

export const actions = {
  async initialize({ commit }) {
    commit(INITIALIZE.STARTED);
    try {
      commit(INITIALIZE.COMPLETED, {
        providers: await httpClient.get(`/api/auth/`)
      });
    } catch (e) {
      logger.error(e);
      commit(INITIALIZE.FAILED);
      throw e;
    }
  }
};

export const mutations = {
  [INITIALIZE.STARTED](state) {
    state.isGetRequestPending = true;
    state.isGetRequestFailed = false;
    state.providers = [];
  },
  [INITIALIZE.FAILED](state) {
    state.isGetRequestPending = false;
    state.isGetRequestFailed = true;
  },
  [INITIALIZE.COMPLETED](state, { providers }) {
    state.isGetRequestPending = false;
    state.providers = providers;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
